<template>
  <div id="main">
    
    <div id="statistics">
      <img :src="defaultImg" style="width:100px;height:100px"/>
      <span>
         无症状感染者: <span style="font-size:20px;color:red">{{statistics.asymptomatic}} </span>例! &nbsp;
         确诊感染者:<span style="font-size:20px;color:red"> {{statistics.confirmed}} </span>例! &nbsp;
         累计死亡 <span style="font-size:20px;color:red">{{statistics.deaths}} </span>例! &nbsp;
         累计确诊(无症状+确诊) <span style="font-size:20px;color:red">{{statistics.total}}</span>例!
         </span>
    </div>
    <div id="myChart"></div>
  </div>
</template>

<script>
import { queryFruitBar, queryYiQingBar } from "../request/api.js";

export default {
  name: "myEcharts",
  data() {
    return {
      defaultImg:require('../assets/gif01.gif'),
      statistics: {
        confirmed: "",
        asymptomatic: "",
        deaths: "",
        total:''
      },
    };
  },
  mounted() {
    let _this = this;
    queryYiQingBar().then(function (response) {
      _this.statistics.confirmed= response.data.data.confirmed.reduce((a,b)=>a+b);
      _this.statistics.asymptomatic = response.data.data.asymptomatic.reduce((a,b)=>a+b);
      _this.statistics.deaths = response.data.data.deaths.reduce((a,b)=>a+b);
      _this.statistics.total = _this.statistics.confirmed+_this.statistics.asymptomatic;
      // 基于准备好的dom,初始化E charts 实例
      let myChart = _this.$echarts.init(
        document.getElementById("myChart"),
        "dark"
      );
      let option = {
        title: {
          text: "上海加油",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          //data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
          data: ["确诊", "无症状", "死亡"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: response.data.data.date,
          axisLabel: {
            show: true, // 是否显示刻度标签，默认显示
            interval: 0, // 坐标轴刻度标签的显示间隔，在类目轴中有效；默认会采用标签不重叠的策略间隔显示标签；可以设置成0强制显示所有标签；如果设置为1，表示『隔一个标签显示一个标签』，如果值为2，表示隔两个标签显示一个标签，以此类推。
            rotate: -50, // 刻度标签旋转的角度，在类目轴的类目标签显示不下的时候可以通过旋转防止标签之间重叠；旋转的角度从-90度到90度
            inside: false, // 刻度标签是否朝内，默认朝外
            margin: 3, // 刻度标签与轴线之间的距离
            //formatter: "{value} Day", // 刻度标签的内容格式器
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "确诊",
            type: "line",
            stack: "Total",
            data: response.data.data.confirmed,
          },
          {
            name: "无症状",
            type: "line",
            stack: "Total",
            data: response.data.data.asymptomatic,
          },
          {
            name: "死亡",
            type: "bar",
            stack: "Total",
            data: response.data.data.deaths,
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
    });
  },
};
</script>

<style scoped>
#myChart {
  width: 100%;
  height: 600px;
  text-align: center;
  margin: 0 auto;
}
#statistics{
  margin-bottom: 1%;
}
.statistics .detail{
  color: red;
}
</style>